<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/admin/dashboard',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Dealers',
            icon: 'nc-icon nc-bullet-list-67',
            path: '/admin/dealers',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'People',
            icon: 'nc-icon nc-single-02',
            path: '/admin/people',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Inbound',
            icon: 'nc-icon nc-headphones-2',
            path: '/admin/inbound',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Outbound',
            icon: 'nc-icon nc-headphones-2',
            path: '/admin/outbound',
          }"
        >
        </sidebar-item>
         <sidebar-item
          :link="{
            name: 'Service',
            icon: 'nc-icon nc-headphones-2',
            path: '/admin/service',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Mystery Shops',
            icon: 'nc-icon nc-headphones-2',
            path: '/admin/mysteryshops',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: '1 on 1',
            icon: 'nc-icon nc-headphones-2',
            path: '/admin/oneonone',
          }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: 'Reports', icon: 'nc-icon nc-app' }">
          <sidebar-item
            :link="{ name: 'Buttons', path: '/components/buttons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Grid System', path: '/components/grid-system' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Panels', path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Sweet Alert', path: '/components/sweet-alert' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Notifications', path: '/components/notifications' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Icons', path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Typography', path: '/components/typography' }"
          ></sidebar-item>
        </sidebar-item>
         <sidebar-item
          :link="{
            name: 'Scripts',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/admin/overview',
          }"
        >
        </sidebar-item>
         <sidebar-item
          :link="{
            name: 'Profile',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/admin/overview',
          }"
        >
        </sidebar-item>
         <!-- <sidebar-item
          :link="{
            name: 'Service',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/admin/overview',
          }"
        >
        </sidebar-item>
        <sidebar-item :link="{ name: 'Forms', icon: 'nc-icon nc-notes' }">
          <sidebar-item
            :link="{ name: 'Regular Forms', path: '/forms/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Forms', path: '/forms/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Validation Forms', path: '/forms/validation' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Wizard', path: '/forms/wizard' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Tables', icon: 'nc-icon nc-paper-2' }">
          <sidebar-item
            :link="{ name: 'Regular Tables', path: '/table-list/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Extended Tables', path: '/table-list/extended' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Paginated Tables', path: '/table-list/paginated' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'Maps', icon: 'nc-icon nc-pin-3' }">
          <sidebar-item
            :link="{ name: 'Google Maps', path: '/maps/google' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Full Screen Maps', path: '/maps/full-screen' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Vector Maps', path: '/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Charts',
            icon: 'nc-icon nc-chart-bar-32',
            path: '/admin/charts',
          }"
        ></sidebar-item>
        <sidebar-item
          :link="{
            name: 'Calendar',
            icon: 'nc-icon nc-single-copy-04',
            path: '/admin/calendar',
          }"
        ></sidebar-item>
        <sidebar-item :link="{ name: 'Pages', icon: 'nc-icon nc-puzzle-10' }">
          <sidebar-item
            :link="{ name: 'User Page', path: '/pages/user' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Login Page', path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Register', path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Lock Screen Page', path: '/lock' }"
          ></sidebar-item>
        </sidebar-item> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import { userService, authenticationService } from "@/_services";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu,
  },
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar("main-panel");

        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    },
  },
  created() {
    if (this.currentUser) {
      userService
        .getById(this.currentUser.id)
        .then((email) => (this.userFromApi = email))
        .catch((error) =>
          console.error("Failed to fetch user details:", error)
        );
    } else {
      console.log("No current user found.");
    }
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
