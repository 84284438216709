<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <div class="col-md">

        <span style="text-align:left;font-size:14px;">
          <h2>Total Score: {{ totalScore }}</h2>
          <!-- <h2>Total Score: {{ totalScore}}</h2> -->
        </span>
        <el-card v-if="this.$store.state.scoring.operatorScoring === 'Y'">
          <h3>Operator Scoring</h3>
          <div>
            <span id="operatorsum" style="text-align:left;font-size:14px;"><b>Operator Score: {{ operatorScore
                }}</b></span>
            <br>
            <div v-for="(question, index) in questions" :key="index">
              <el-radio-group v-model="question.score" @change="calculateOperatorScore">
                <el-radio-button :label="'yes'">Yes</el-radio-button>
                <el-radio-button :label="'no'">No</el-radio-button>
                <el-radio-button :label="'na'">N/A</el-radio-button>
              </el-radio-group>
              <span class="question">{{ question.text }}</span>
              <br>
            </div>
            <br>
            <strong>Operator Comments:</strong>
            <el-input type="textarea" :rows="4" v-model="operatorComments"></el-input>
          </div>
        </el-card>
        <!-- Greeting Categories -->
        <br><br>
        <question-section title="Greeting" score-title="Greeting Score" :questions="greetingScoreQuestions"
          :computedScore="greetingScore"></question-section>


        <!-- Qualifying Generic Categories -->
        <br><br>
        <div v-if="showGeneric || !showSpecific">
          <question-section title="Qualifying Generic" score-title="Qualifying Generic Score"
            :questions="qualifyGenericQuestions" :computedScore="qualifyingGeneric"></question-section>

        </div>
        <!-- Qualifying Generic Categories -->
        <br><br>
        <div v-if="showSpecific || !showGeneric">
          <question-section title="Qualifying Specific" score-title="Qualifying Specific Score"
            :questions="qualifyingSpecificQuestions" :computedScore="qualifyingSpecific"></question-section>
        </div>
        <br><br>
        <div>
          <question-section title="Obtaining Contact Info" score-title="Contact Info Score"
            :questions="obtainingContactInfoQuestions" :computedScore="obtainingContactInfo"></question-section>

        </div>
        <br>
        <br>
        <question-section title="Selling the Appointment" score-title="Appointment Score"
          :questions="appointmentQuestions" :computedScore="appointmentScore"></question-section>

      </div>
      <div class="col-md">
        <br>
        <question-section title="F.A.Q" score-title="F.A.Q. Score" :questions="questionsFaq"
          :computedScore="faqScore"></question-section>

        <div>
          <br>
          <question-section title="Driving Toward Closure" score-title="Closure Score" :questions="questionsDTC"
            :computedScore="dtcScore"></question-section>
        </div>
        <!-- Directions to Dealership -->
        <br>
        <br>
        <div>
          <question-section title="Directions to Dealership" score-title="Directions Score" :questions="questionsDTOD"
            :computedScore="dtodScore"></question-section>

        </div>
        <!-- Other/Customer Service  -->
        <br>
        <br>
        <div>
          <span class="category">Other/Customer Service</span>
          <span id="greetingsum" style="text-align:left; font-size:12px;">
            &nbsp;&nbsp;<b>(Other Score: {{ ocScore }})</b>
          </span>
          <br><br>
          <div v-for="question in questionsOC" :key="question.id" class="question-container">
            <span v-if="question.type === 'switch'">
              <l-switch v-model="question.score">
                <template #on><i class="fa fa-check"></i></template>
                <template #off><i class="fa fa-times"></i></template>
              </l-switch>
              <span class="question">{{ question.text }}</span>
            </span>
            <span v-else-if="question.type === 'radio'">
              <el-radio-group v-model="question.score">
                <el-radio-button :label="'yes'">Yes</el-radio-button>
                <el-radio-button :label="'no'">No</el-radio-button>
                <el-radio-button :label="'na'">N/A</el-radio-button>
              </el-radio-group>
              <span class="question">{{ question.text }}</span>
            </span>
            <br><br>
          </div>
        </div>
        <br>
        <br>
        <div>
          <span class="category">Source Code</span>
          <br><br>
          <div v-for="question in questionsSC" :key="question.id" class="question-container">
            <span v-if="question.type === 'switch'">
              <l-switch v-model="question.score">
                <template #on><i class="fa fa-check"></i></template>
                <template #off><i class="fa fa-times"></i></template>
              </l-switch>
              <span class="question">{{ question.text }}</span>
            </span>
            <br><br>
          </div>
          <el-select v-if="questionsSC.length > 0 && questionsSC[0].score" class="select-danger" size="large"
            placeholder="Single Select" v-model="selectedSource">
            <el-option v-for="source in sources" :key="source.sourceid" :value="source.sourcename"
              class="select-danger">
            </el-option>
          </el-select>
        </div>
        <fg-input label="Customer's Name" type="text" v-model="customerName">
        </fg-input>
        <fg-input label="Customer's Phone" type="text" v-model="customerPhone">
        </fg-input>
        <fg-input label="Secret Code" type="text" v-model="secretCode">
        </fg-input>
        <fg-input label="Comments">
          <textarea class="form-control" placeholder="Comments" v-model="comments" rows="3"></textarea>
        </fg-input>
        <file-uploader @file-selected="onFileSelected"></file-uploader>

        <br>
        <l-button @click="sendScoring()" type="primary" wide>Submit</l-button>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination, Form, RadioButton, RadioGroup } from 'element-ui'
import { mapActions, mapState } from 'vuex';

import {
  Switch as LSwitch,
} from 'src/components/index'
import FileUploader from './FileUploader';
import QuestionSection from './Questions/QuestionSection.vue';

export default {
  name: 'InboundScoreSheet',
  components: {
    // intro,
    'el-card': Card,
    // 'el-table': Table,
    // 'el-table-column': TableColumn,
    // // 'l-pagination': Pagination,
    'el-input': Input,
    // 'el-button': Button,
    // 'el-form': Form,
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    FileUploader,
    questionSection: QuestionSection
  },
  data() {
    return {
      user: localStorage.getItem('currentUser'),
      selectedSource: '',
      showRatingSection: false,
      operatorScore: 0,
      stpScore: 0,
      sellingTheApointement: 0,
      operatorComments: '',
      audioFile: null,
      questions: [
        { score: 'na', text: 'Thank you for choosing' },
        { score: 'na', text: 'Used dealership name' },
        { score: 'na', text: 'Used first name only' },
        { score: 'na', text: 'Asked "How may I direct your call"' },
        { score: 'na', text: 'Used proper tone and inflections' },
      ],
      // other data properties...

      greetingScoreQuestions: [
        { id: 'q1', score: false, text: 'Used thank you for choosing', type: 'switch' },
        { id: 'q2', score: false, text: 'Used dealership name', type: 'switch' },
        { id: 'q3', score: false, text: 'Used first name only', type: 'switch' },
        { id: 'q4', score: false, text: 'Asked, "How may I help you"', type: 'switch' },
        { id: 'q5', score: false, text: 'Used proper tone & inflections', type: 'switch' },

      ],

      qualifyGenericQuestions: [
        { id: 'q6', score: false, text: 'Asked 3-5 questions', type: 'switch' },
        { id: 'q7', score: false, text: 'Asked in closed ended form at', type: 'switch' },
        { id: 'q8', score: false, text: 'Demonstrated proper product knowledge', type: 'switch' },
      ],

      qualifyingSpecificQuestions: [
        { id: 'q9', score: false, text: 'Did sp make them feel good about choice', type: 'switch' },
        { id: 'q10', score: false, text: 'Did sp create urgency', type: 'switch' },
        { id: 'q11', score: false, text: 'Did sp open funnel to other vehicles', type: 'switch' },
      ],
      obtainingContactInfoQuestions: [
        { id: 'q12', score: false, text: 'Used closed ended format', type: 'switch' },
        { id: 'q13', score: false, text: 'Obtained number', type: 'switch' },
        { id: 'q14', score: false, text: 'Asked for last name first', type: 'switch' },
        { id: 'q15', score: false, text: 'Obtained both names', type: 'switch' },
        { id: 'q16', score: false, text: 'Gave customer reason for taking 10 min', type: 'switch' },
        { id: 'q17', score: false, text: 'Gave customer a reason to stay on the phone', type: 'switch' },
      ],
      appointmentQuestions: [
        { id: 'q18', score: false, text: 'Did sp even inquire about appt', type: 'switch' },
        { id: 'q19', score: false, text: 'Used closed ended format when asking', type: 'switch' },
        { id: 'q20', score: false, text: 'Appt set?', type: 'switch' },
        { id: 'q21', score: false, text: 'Did sp ask with authority', type: 'switch' },
        { id: 'q22', score: 'na', text: 'Asked for appt more than once', type: 'radio' },
        { id: 'q23', score: 'na', text: 'Asked for tentative appt', type: 'radio' },
        { id: 'q24', score: 'na', text: 'Made phone appt to follow up', type: 'radio' }
      ],
      questionsFaq: [
        { id: 'q25', score: 'na', text: 'Did sp overcome FAQ', type: 'radio' }
      ],
      questionsDTC: [
        { id: 'q26', score: false, text: 'Did sp ask if customer had pen/pencil', type: 'switch' },
        { id: 'q27', score: false, text: 'Did sp spell last name before giving info', type: 'switch' },
        { id: 'q28', score: 'na', text: 'Did sp repeat appt time', type: 'radio' },
        { id: 'q29', score: 'na', text: 'Did sp ask customer to call if running late', type: 'radio' },
        { id: 'q30', score: false, text: 'Did sp ask customer to call if they could not make appt', type: 'switch' },
      ],
      questionsDTOD: [
        { id: 'q31', score: false, text: 'Did sp ask if customer knew where to go', type: 'switch' },
        { id: 'q32', score: false, text: 'Did sp give directions', type: 'switch' },
      ],
      questionsOC: [
        { id: 'q33', score: false, text: 'Did sp give availability before contact info', type: 'switch' },
        { id: 'q34', score: false, text: 'Did sp actively listen', type: 'switch' },
        { id: 'q35', score: false, text: 'Did sp follow script in order', type: 'switch' },
      ],
      questionsSC: [
        { id: 'q36', score: false, text: 'Did sp obtain source code', type: 'switch' },
      ],
    };

  },
  computed: {
    ...mapState('inbound', [
      'customerName', 'customerPhone', 'secretCode', 'comments'
      // Include other state properties as needed
    ]),
    customerName: {
      get() {
        return this.$store.state.inbound.customerName;
      },
      set(value) {
        this.$store.commit('inbound/updateCustomerName', value);
      }
    },
    customerPhone: {
      get() {
        return this.$store.state.inbound.customerPhone;
      },
      set(value) {
        this.$store.commit('inbound/updateCustomerPhone', value);
      }
    },
    secretCode: {
      get() {
        return this.$store.state.inbound.secretCode;
      },
      set(value) {
        this.$store.commit('inbound/updateSecretCode', value);
      }
    },
    comments: {
      get() {
        return this.$store.state.inbound.comments;
      },
      set(value) {
        this.$store.commit('inbound/updateComments', value);
      }
    },

    totalScore() {
      let total = (this.greetingScore * 0.1425 + this.qualifyingGeneric * 0.143 + this.qualifyingSpecific * 0.143 +
        this.obtainingContactInfo * 0.1425 + this.appointmentScore * 0.1425 + this.faqScore * 0.054 + this.dtcScore * 0.124 + this.dtodScore * 0.126 + this.ocScore * 0.126).toFixed(2).toString();
      this.updateTotalScore(total);
      return total;
    },
    qualifyingGeneric() {
      // Calculate score by counting only where score is true
      let score = this.qualifyGenericQuestions.filter(question => question.score).length;
      console.log("Qualifying generic:", score);
      return parseFloat(((score / 3) * 5).toFixed(2)); // Ensure this is a number
    },
    qualifyingSpecific() {
      // Calculate score by counting only where score is true
      let score = this.qualifyingSpecificQuestions.filter(question => question.score).length;
      console.log("Qualifying Specific:", score);
      return parseFloat(((score / 3) * 5).toFixed(2)); // Ensure this is a number
    },
    obtainingContactInfo() {
      // Calculate score by counting only where score is true
      let score = this.obtainingContactInfoQuestions.filter(question => question.score).length;
      console.log("Contact info:", score);
      return parseFloat(((score / 6) * 5).toFixed(2)); // Ensure this is a number
    },
    greetingScore() {
      // Calculate score by counting only where score is true
      let score = this.greetingScoreQuestions.filter(question => question.score).length;
      console.log("Greeting score:", score);
      return score; // Just return the count of true values for this example
    },
    appointmentScore() {
      let score = 0;
      let questionsused = 0;
      let pointvalue = 1;
      let switchused = 0;

      // Iterate over all questions and determine the active count and scoring
      this.appointmentQuestions.forEach(question => {
        if (question.type === 'switch' && question.score) {
          questionsused++;
          switchused++;
          //score += 1; // assuming each 'true' switch counts as 1 point
        } else if (question.type === 'radio' && question.score !== 'na') {
          questionsused++;
        }
      });

      // Determine the point value based on the active question count
      switch (questionsused) {
        case 4: pointvalue = 1; break;
        case 5: pointvalue = 0.83; break;
        case 6: pointvalue = 0.715; break;
        case 7: pointvalue = 0.625; break;

      }

      // Recalculate the total score using the point value
      this.appointmentQuestions.forEach(question => {
        if (question.type === 'switch') {
          // Assuming there's a specific question with an id or similar property
          // that should be double scored
          if (question.id === 'q20' && question.score) { // 'q20' is an example ID
            score += pointvalue * 2; // Double points for "Appt set"
          } else if (question.score) {
            score += pointvalue;
          }
        } else if (question.type === 'radio' && question.score === 'yes') {
          score += pointvalue;
        }
      });
      return score;

    },
    dtcScore() {
      let score = 0;
      let questionsUsed = 3;  // Start with the base questions that always count

      // Adjust questionsUsed based on radio responses
      this.questionsDTC.forEach(question => {
        if (question.type === 'radio' && question.score !== 'na') {
          questionsUsed++;
        }
      });

      // Determine the point value
      let pointValue = 0;
      switch (questionsUsed) {
        case 3:
          pointValue = 1.66;
          break;
        case 4:
          pointValue = 1.25;
          break;
        case 5:
          pointValue = 1;
          break;
      }

      // Calculate score for closure
      this.questionsDTC.forEach(question => {
        if (question.type === 'switch' && question.score) {
          score += pointValue;  // Add score if the switch is true
        } else if (question.type === 'radio' && question.score === 'yes') {
          score += pointValue;  // Add score if the radio button is yes
        }
      });

      return score;  // Format to two decimal places for display
    },
    dtodScore() {
      let score = 0;
      let pointValue = 2.5;
      this.questionsDTOD.forEach(question => {
        if (question.type === 'switch' && question.score) {
          score += pointValue;  // Add score if the switch is true
        } else if (question.type === 'radio' && question.score === 'yes') {
          score += pointValue;  // Add score if the radio button is yes
        }
      });
      return score;
    },
    ocScore() {
      let score = 0;
      let pointValue = 1.66;
      this.questionsOC.forEach(question => {
        if (question.type === 'switch' && question.score) {
          score += pointValue;  // Add score if the switch is true
        } else if (question.type === 'radio' && question.score === 'yes') {
          score += pointValue;  // Add score if the radio button is yes
        }
      });
      return score;
    },

    faqScore() {
      // Count the number of true values in the switches object
      if (this.questionsFaq[0].score === 'yes') {
        let score = Object.values(this.questionsFaq).filter(value => value).length;
        return score * 5
      }
      else {
        return 0;
      }
    },
    showGeneric() {
      return this.qualifyGenericQuestions.some(question => question.score);
    },
    // Return true if any specific questions are actively marked as true
    showSpecific() {
      return this.qualifyingSpecificQuestions.some(question => question.score);
    }
  },
  methods: {
    ...mapActions('inbound', ['updateScore', 'initializeScores', 'updateTotalScore']),
    async fetchScoringData() {
      try {
        const scoringId = this.$route.params.id;  // Get the ID from the router parameter
        console.log("scoring id" , scoringId , "vuex newScoringId", this.$store.state.scoring.newScoringId);
        const newScoringId = this.$store.state.scoring.newScoringId; // Get newScoringId from the store

        // Proceed only if scoringId is not null or undefined
        if (newScoringId) {
          console.warn("Scoring ID is undefined or new");
          return;
        }

        // Fetch the scoring data
        const response = await fetch(`/api/scoring/${scoringId}`);
        if (!response.ok) throw new Error('Failed to fetch scoring data');
        const data = await response.json();
        console.log(data);
        this.populateScoringData(data);  // Populate data in the form fields
      } catch (error) {
        console.error("Error fetching scoring data:", error);
      }
    },
    populateScoringData(data) {
      // Update basic info
      this.$store.commit('inbound/updateCustomerName', data.customername);
      this.$store.commit('inbound/updateCustomerPhone', data.customerphone);
      this.$store.commit('inbound/updateSecretCode', data.secretcode);
      this.$store.commit('inbound/updateComments', data.comment1 || '');

      // Update each question group with appropriate scoring data
      this.greetingScoreQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.qualifyGenericQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.qualifyingSpecificQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.obtainingContactInfoQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.appointmentQuestions.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.questionsFaq.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.questionsDTC.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.questionsDTOD.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.questionsOC.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });
      this.questionsSC.forEach(question => {
        if (question.type === 'switch') {
          question.score = data[question.id + '_score'] === 'Y';
        } else if (question.type === 'radio') {
          question.score = data[question.id + '_score'] === 'Y' ? 'yes' : (data[question.id + '_score'] === 'N' ? 'no' : 'na');
        }
      });

      // Update category scores directly tied to UI elements or computed properties
      this.selectedSource = data.source;
      this.audioFile = {
        name: data.audiofile,
        folder: data.audiofolder
      };

      // Assuming operatorScore is a computed property based on `questions` array
      this.questions.forEach((question, index) => {
        if (data[`q${index + 1}_score`]) {
          question.score = data[`q${index + 1}_score`] === 'Y' ? 'yes' : (data[`q${index + 1}_score`] === 'N' ? 'no' : 'na');
        }
      });

      // Trigger any necessary re-computation or updates
      this.calculateOperatorScore();
    },
    onFileSelected(file) {
      this.audioFile = file; // Handle the file locally in the parent component
      console.log('File selected:', this.audioFile);
    },
    async sendScoring() {
      const formData = new FormData();
      const audioFileState = this.audioFile; // Assume state contains the needed data
      console.log(audioFileState); // It should log a File object
      console.log(typeof audioFileState); // Should log 'object'
      console.log(audioFileState instanceof Blob); // Should log true because File is a subclass of Blob

      // Append the file if available and correctly retrieved
      if (audioFileState instanceof File) {
        formData.append('audiofile', audioFileState, audioFileState.name);
      } else {
        console.error('No file or incorrect file type');
      }
      // // Append the file if available
      // if (this.audioFile && this.audioFile instanceof Blob) {
      //     formData.append('audiofile', this.audioFile, this.audioFile.name);
      //     console.log('File appended successfully');
      //   } else {
      //     console.error('No valid audio file:', this.audioFile);
      //   }

      // Append other form data
      formData.append('personid', this.$store.state.scoring.selectedPerson.personid);
      formData.append('dealerid', this.$store.state.scoring.selectedDealer.dealerid);
      formData.append('secretcode', this.$store.state.inbound.secretCode);
      //customername: this.$store.state.inbound.customerName,
      //     customerphone: this.$store.state.inbound.customerPhone,
      formData.append('customername', this.$store.state.inbound.customerName);
      formData.append('customerphone', this.$store.state.inbound.customerPhone);
      formData.append('coachid', this.$store.state.auth.userData.userId);
      formData.append('coachname', this.$store.state.auth.userData.firstname + ' ' + this.$store.state.auth.userData.lastname);
      formData.append('comments', this.$store.state.inbound.comments);
      formData.append('sourcecode', this.questionsSC[0].score ? 'Yes' : 'No');
      formData.append('source', this.selectedSource);
      formData.append('othersource', ''); // Include if there's a field for other sources
      formData.append('appointmentset', this.appointmentQuestions.find(q => q.id === 'q20').score ? 'yes' : 'no');
      formData.append('faqlist', this.questionsFaq.map(q => q.score).join(','));
      formData.append('totalscore', this.totalScore);

      // Append question scores
      [
        ...this.greetingScoreQuestions,
        ...this.qualifyGenericQuestions,
        ...this.qualifyingSpecificQuestions,
        ...this.obtainingContactInfoQuestions,
        ...this.appointmentQuestions,
        ...this.questionsFaq,
        ...this.questionsDTC,
        ...this.questionsDTOD,
        ...this.questionsOC,
        ...this.questionsSC
      ].forEach(question => {
        const score = question.type === 'switch' ? (question.score ? 'Y' : 'N') :
          (question.type === 'radio' ? (question.score === 'yes' ? 'Y' : (question.score === 'no' ? 'N' : 'Z')) : question.score);
        formData.append(question.id + "_score", score);
      });

      // Append category scores if needed
      formData.append('cat1_score', this.greetingScore);
      formData.append('cat2_score', this.qualifyingGeneric);
      formData.append('cat3_score', this.qualifyingSpecific);
      formData.append('cat4_score', this.obtainingContactInfo);
      formData.append('cat5_score', this.appointmentScore);
      formData.append('cat6_score', this.faqScore);
      formData.append('cat7_score', this.dtcScore);
      formData.append('cat8_score', this.dtodScore);
      formData.append('cat9_score', this.ocScore);
      formData.append('cat1_name', 'Greeting');
      formData.append('cat2_name', 'Qualifying Generic');
      formData.append('cat3_name', 'Qualifying Specific');
      formData.append('cat4_name', 'Obtaining Contact Info');
      formData.append('cat5_name', 'Selling the Appointment');
      formData.append('cat6_name', 'FAQ');
      formData.append('cat7_name', 'Driving Toward Closure');
      formData.append('cat8_name', 'Directions to Dealership');
      formData.append('cat9_name', 'Other/Customer Service');
      formData.append('emailsent', 'N');
      formData.append('deleted', 'N');
      formData.append('coachable', 'Y');
      formData.append('questionscount', 36);
      formData.append('catscount', 9);
      try {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        const token = user && user.token;
        const response = await fetch(`/api/process-form`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        console.log('Success:', result);
        // Handle success - e.g., redirect or notify user
      } catch (error) {
        console.error('Error sending form data:', error);
      }
    },

    fetchSources() {
     
      fetch(`/api/sources`)
        .then(res => res.json())
        .then(data => this.sources = data)
        .catch(err => console.error('Error fetching sources:', err));
    },
    handleScoreChange(questionId, score) {
      this.updateScore({ questionId, value: score });
    },
    calculateOperatorScore() {
      // Implement scoring logic here
      this.operatorScore = this.questions.reduce((acc, question) => {
        return acc + (question.score === 'yes' ? 1 : 0); // Example scoring logic
      }, 0);
    },
    calculateSTPScore() {
      const yesCount = this.questionsAppt.reduce((acc, question) => {
        return acc + (question.score === 'yes' ? 1 : 0);
      }, 0);

      switch (yesCount) {
        case 1:
          this.stpScore = 0.12;
          break;
        case 2:
          this.stpScore = 0.2;
          break;
        case 3:
          this.stpScore = 0.27;
          break;
        default:
          this.stpScore = 0;
      }
    }
    // other methods...
  },
  created() {
    const allQuestions = [
      ...this.greetingScoreQuestions,
      ...this.qualifyGenericQuestions,
      ...this.qualifyingSpecificQuestions,
      ...this.obtainingContactInfoQuestions,
      ...this.appointmentQuestions,
      ...this.questionsFaq,
      ...this.questionsDTC,
      ...this.questionsDTOD,
      ...this.questionsOC
    ];
    this.initializeScores(allQuestions);

    // Dynamic watchers initialization
    const questionGroups = [
      'greetingScoreQuestions',
      'qualifyGenericQuestions',
      'qualifyingSpecificQuestions',
      'obtainingContactInfoQuestions',
      'appointmentQuestions',
      'questionsFaq',
      'questionsDTC',
      'questionsDTOD',
      'questionsOC'
    ];

    questionGroups.forEach(group => {
      this[group].forEach(question => {
        this.$watch(
          () => question.score,
          (newVal) => {
            console.log(newVal, question.id);
            this.handleScoreChange(`${question.id}_score`, newVal);
          }
        );
      });
    });
    this.fetchScoringData();

  },
  mounted() {
    this.fetchSources();
  },
};

</script>
<style>
.question-container {
  margin-bottom: -22px;
  /* Adjust this value to increase or decrease spacing */
}
</style>
