<template>
  <div>
    <div class="alert alert-info">
      <strong>Normal User</strong> - U: user P: user<br />
      <strong>Administrator</strong> - U: admin P: admin
    </div>
    <h2>Login</h2>
    <form @submit.prevent="onSubmit()">
      <div class="form-group">
        <label for="email">email</label>
        <input
          type="text"
          v-model.trim="$v.email.$model"
          name="email"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.email.$error }"
        />
        <div v-if="submitted && !$v.email.required" class="invalid-feedback">
          email is required
        </div>
      </div>
      <div class="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          v-model.trim="$v.password.$model"
          name="password"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.password.$error }"
        />
        <div v-if="submitted && !$v.password.required" class="invalid-feedback">
          Password is required
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary" :disabled="loading">
          <span
            class="spinner-border spinner-border-sm"
            v-show="loading"
          ></span>
          <span>Login</span>
        </button>
      </div>
      <div v-if="error" class="alert alert-danger">{{ error }}</div>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { router } from "@/_helpers";
import { authenticationService } from "@/_services";
//import { configureFakeBackend } from "@/_helpers/fake-backend";
import { authenticateUser } from "@/_helpers/authenticateUser";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      returnUrl: "",
      error: "",
    };
  },
  validations: {
    email: { required },
    password: { required },
  },
  created() {
    console.log(authenticationService.currentUserValue);
    console.log(authenticationService);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      return router.push("/");
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.$route.query.returnUrl || "/";
  },
  methods: {
    onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      // Log the data to be sent
      console.log("Attempting to log in with:", this.email, this.password);

      // Ensure the data is not empty
      const requestData = {
        email: this.email,
        password: this.password,
      };

      // Log the request data
      console.log("Request data:", requestData);
      // Here you could call configureFakeBackend if needed
      authenticateUser(this.email, this.password);
      console.log(this.email);
      authenticationService.login(this.email, this.password).then(
        (user) => {
          // Login was successful, redirect to the return URL
          router.push(this.returnUrl);
        },
        (error) => {
          // Handle error case
          this.error = error;
          this.loading = false;
        }
      );
    },
  },
};
</script>
