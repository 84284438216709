<template>
  <div class="container-fluid">
    <div class="row justify-content-start">
      <!-- <h1>Home</h1>
      <br>
      <p>Welcome {{ this.$store.state.auth.userData.firstname }} {{ this.$store.state.auth.userData.lastname }}</p>
      <br>
      <p>Your role is: <strong>{{ this.$store.state.auth.userData.role }}</strong>.</p>
      <br>
      <p>This page can be accessed by only {{ this.$store.state.auth.userData.role }}.</p> -->
      <div class="col-auto w-100">
        <card>
          <form v-if="(this.$store.state.auth.userData.role === 'coach assistant')" @submit.prevent="submitForm">
            <!-- Dealers Dropdown using el-select for UI consistency -->
            <el-select v-model="dealers" placeholder="Select Dealer" class="mb-4">
              <el-option label="Select Dealer" value="0"></el-option>
              <el-option v-for="dealer in dealersList" :key="dealer.dealerid" :label="dealer.dealername"
                :value="dealer.dealerid"></el-option>
            </el-select>

            <!-- People Dropdown using el-select -->
            <el-select v-model="people" placeholder="Select Person" class="mb-4">
              <el-option v-for="person in peopleList" :key="person.personid"
                :label="person.firstname + ' ' + person.lastname" :value="person.personid"></el-option>
            </el-select>

            <!-- Call Type Dropdown using el-select -->
            <el-select v-model="calltype" placeholder="Select Call Type" class="mb-4">
              <el-option label="Inbound" value="inbound"></el-option>
              <el-option label="Outbound" value="outbound"></el-option>
              <el-option label="Service" value="service"></el-option>
              <el-option label="1 on 1" value="1on1"></el-option>
              <el-option label="Mystery Shop" value="mystery"></el-option>
            </el-select>

            <!-- Date to Coach using el-date-picker -->
            <div class="mb-4">
              <el-date-picker v-model="coachdate" type="date" placeholder="Date to coach this call"></el-date-picker>
            </div>

            <!-- Comment Textarea using standard textarea for simplicity -->
            <div class="mb-4">
              <textarea v-model="comment" id="comment" rows="1" cols="50" placeholder="Enter comment"
                class="el-textarea__inner"></textarea>
            </div>

            <!-- Submit Button using el-button for UI consistency -->
            <el-button type="success" @click="submitForm">Submit</el-button>
          </form>
        </card>
        <!-- <card>
          <div slot="header">
            <h4 class="card-title">Sessions Table</h4>
            <p class="category">A list of scheduled sessions</p>
          </div>
          <el-table :data="sessions">
            <el-table-column prop="dealername" label="Dealer"></el-table-column>
            <el-table-column label="Person">
              <template slot-scope="scope">
                {{ scope.row.firstname }} {{ scope.row.lastname }}
              </template>
            </el-table-column>
            <el-table-column prop="scheduledcalltype" label="Call Type"></el-table-column>
            <el-table-column label="Date">
              <template slot-scope="scope">
                {{ formatDate(scope.row.scheduledcoachdate) }}
              </template>
            </el-table-column>
            <el-table-column prop="scheduledcomment" label="Comment"></el-table-column>
            <el-table-column label="Delete">
              <template slot-scope="scope">
                <a :href="`/delete/${scope.row.scheduledid}`">Delete</a>
              </template>
            </el-table-column>
          </el-table>
        </card> -->
        <card>
          <div>
            <!-- Search -->
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-input v-model="searchQuery" class="mb-3" placeholder="Search sessions" suffix-icon="el-icon-search"
                @input="handleSearch">
              </el-input>
            </div>

            <!-- Table -->
            <el-table :data="queriedData" style="width: 100%;" border>
              <el-table-column prop="dealername" label="Dealer"></el-table-column>
              <el-table-column label="Person" min-width="120">
                <template slot-scope="scope">
                  {{ scope.row.firstname }} {{ scope.row.lastname }}
                </template>
              </el-table-column>
              <el-table-column prop="scheduledcalltype" label="Call Type"></el-table-column>
              <el-table-column label="Date" min-width="120">
                <template slot-scope="scope">
                  {{ formatDate(scope.row.scheduledcoachdate) }}
                </template>
              </el-table-column>
              <el-table-column prop="scheduledcomment" label="Comment"></el-table-column>
              <el-table-column fixed="right" label="Actions" width="100">
                <template slot-scope="scope">
                  <el-button type="text" @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- Pagination -->
            <l-pagination v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total"
              @current-change="handlePageChange">
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>

import { authenticationService } from "../../../_services/authentication.service.js";
import { userService } from "../../../_services/user.service.js";
import { DatePicker, TimeSelect, Slider, Tag, Input, Button, Select, Option, Card, Table, TableColumn, Pagination } from 'element-ui'
import Fuse from 'fuse.js';
import { mapState } from "vuex";

export default {
  components: {
    // 'l-card': Card,
    'el-table': Table,
    'el-table-column': TableColumn,
    'l-pagination': Pagination,
    'el-input': Input,
    'el-button': Button,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Slider.name]: Slider,
    [Tag.name]: Tag,
    [Input.name]: Input,
    [Button.name]: Button,
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      currentUser: authenticationService.currentUserValue,
      userFromApi: null,
      dealers: "",
      people: "",
      calltype: "",
      coachdate: new Date().toISOString().substr(0, 10), // default to today's date
      comment: "",
      dealersList: [], // Populate this with data from your backend
      peopleList: [], // Populate this with data based on selected dealer
      sessions: [],
      searchResult:[],
      sessionsSearch: [],
      searchQuery: '',
      pagination: {
        currentPage: 1,
        perPage: 10,
        total: 0
      },
      fuse: null
    };
  },
  computed: {
    ...mapState('session', [ 'total']),
    //math
    pagedSessions() {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return this.sessions.slice(start, end);
    },
    queriedData() {
      if (!this.searchQuery) return this.paginate(this.sessions);
      else return this.paginate(this.sessionsSearch);
    }
  },
  watch: {
    // Watch the dealers variable for changes
    dealers(newVal) {
      if (newVal !== "" && newVal !== "0") {
        this.fetchPeople(newVal); // Fetch people when a dealer is selected
      } else {
        this.peopleList = []; // Reset peopleList if no dealer is selected
      }
    },
  },
  methods: {
    handleSearch() {
      if (this.searchQuery.trim() === '') {
        // If the search query is empty, reset to the full list from Vuex store
        this.sessionsSearch = this.$store.state.session.sessions;
      } else {
        // Perform search using Fuse.js on Vuex state's sessions
        const fuse = new Fuse(this.$store.state.session.sessions, {
          keys: ["dealername", "firstname", "lastname", "scheduledcalltype", "scheduledcomment"],
          includeScore: true
        });
        const results = fuse.search(this.searchQuery);
        // Update the component's sessions with the search result
        this.sessionsSearch = results.map(result => result.item);
        this.searchResult.push(this.sessionsSearch);
      }
      // Adjust pagination based on filtered results
      this.pagination.currentPage = 1;
      this.pagination.total = this.sessionsSearch.length;
    },
    fetchSessions() {
      const apiUrl = process.env.VUE_APP_API_URL;
      console.log("OVERVIEW",apiUrl);
      fetch(`/api/scheduled-sessions`)
        .then(response => response.json())
        .then(data => {
          this.sessions = data;
          this.$store.commit('session/SET_SESSIONS', this.sessions);
          this.pagination.total = this.sessions.length;

        })
        .catch(error => console.error('Error fetching sessions:', error));

    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    fetchDealers() {
      fetch(`/api/dealers`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Handle your data here
          this.dealersList = data;
          this.$store.dispatch('dealers/setDealersData', data);
        })
        .catch((error) => {
          console.error("Error fetching dealers:", error);
        });
    },
    fetchPeople(dealerId) {
      fetch(`/api/people?dealerid=${dealerId}`)
        .then((response) => response.json())
        .then((data) => (this.peopleList = data))
        .catch((error) => console.error("Error fetching people:", error));
    },
    submitForm() {
      const selectedPerson = this.peopleList.find(
        (person) => person.personid === this.people
      );
      const formData = {
        dealers: this.dealers,
        calltype: this.calltype,
        coachdate: this.coachdate,
        comment: this.comment,
        personid: this.people, // Assuming this.people is the person ID
        personname: selectedPerson
          ? `${selectedPerson.firstname}${selectedPerson.lastname}`
          : "", // Extract person name based on ID
      };
      const token = JSON.parse(localStorage.getItem("currentUser")).token; // Adjust according to your storage key
      fetch(`/insert-scheduled-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the token here

        },
        body: JSON.stringify(formData),
      })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch((error) => console.error("Error:", error));
    },
    paginate(items) {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      return items.slice(start, end);
    },
    handlePageChange(newPage) {
      this.pagination.currentPage = newPage;
    },
  },
  created() {
    userService
      .getById(this.currentUser.id)
      .then((email) => (this.userFromApi = email));
  },
  mounted() {
    this.fetchDealers();
    this.fetchSessions();
    this.fuse = new Fuse(this.sessions, { keys: ["dealername", "firstname", "lastname", "scheduledcalltype", "scheduledcomment"], includeScore: true });
  },
};
</script>
<style></style>
