// In your Vuex store (e.g., store/modules/sessions.js)

const state = {
  sessions: [],
  total: 0,
};

const mutations = {
  SET_SESSIONS(state, sessions) {
    state.sessions = sessions;
    state.total = sessions.length; // Assuming you're fetching all sessions at once
  },
};

// const actions = {
//   fetchSessions({ commit }) {
//     fetch('http://localhost:3000/api/scheduled-sessions')
//       .then(response => response.json())
//       .then(data => {
//         commit('SET_SESSIONS', data);
//       })
//       .catch(error => console.error('Error fetching sessions:', error));
//   },
// };

export default {
  namespaced: true,
  state,
  mutations,
};
