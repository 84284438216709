//import config from 'config';
import { handleResponse, requestOptions } from '@/_helpers';

export const userService = {
    getAll,
    getById
};

function getAll() {
    // const apiUrl = process.env.VUE_APP_API_URL;
    // console.log(apiUrl, "userService");
    return fetch(`/users`, requestOptions.get())
        .then(handleResponse);
}

function getById(id) {
    // const apiUrl = process.env.VUE_APP_API_URL;
    //     console.log(apiUrl, "userService1");
    return fetch(`/users/${id}`, requestOptions.get())
        .then(handleResponse);
}