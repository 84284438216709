var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',[_c('user-menu'),_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'nc-icon nc-chart-pie-35',
          path: '/admin/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Dealers',
          icon: 'nc-icon nc-bullet-list-67',
          path: '/admin/dealers',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'People',
          icon: 'nc-icon nc-single-02',
          path: '/admin/people',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Inbound',
          icon: 'nc-icon nc-headphones-2',
          path: '/admin/inbound',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Outbound',
          icon: 'nc-icon nc-headphones-2',
          path: '/admin/outbound',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Service',
          icon: 'nc-icon nc-headphones-2',
          path: '/admin/service',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mystery Shops',
          icon: 'nc-icon nc-headphones-2',
          path: '/admin/mysteryshops',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: '1 on 1',
          icon: 'nc-icon nc-headphones-2',
          path: '/admin/oneonone',
        }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Reports', icon: 'nc-icon nc-app' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Buttons', path: '/components/buttons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Grid System', path: '/components/grid-system' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Panels', path: '/components/panels' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sweet Alert', path: '/components/sweet-alert' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Notifications', path: '/components/notifications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Icons', path: '/components/icons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Typography', path: '/components/typography' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Scripts',
          icon: 'nc-icon nc-chart-pie-35',
          path: '/admin/overview',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Profile',
          icon: 'nc-icon nc-chart-pie-35',
          path: '/admin/overview',
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }