import axios from 'axios';
import Vue from 'vue';

// Function to authenticate the user using the real backend
export async function authenticateUser(email, password) {
    try {
        if (!email || !password) {
            throw new Error('Email and password are required');
        }
        // const apiUrl = Vue.prototype.$config.apiUrl;
        // console.log(`Using API URL: ${apiUrl}`);

        //const apiUrl = 'http://localhost:8080/';

        const response = await axios.post(`api/users/authenticate`, {
            email: email,
            password: password
        });
        // Handle the response data as needed in your application
        console.log("Authentication successful", response.data);
        return response.data;
    } catch (error) {
        // Handle errors appropriately in your application
        console.error("Authentication failed", error);
        throw error;
    }
}
