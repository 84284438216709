<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <h3 class="card-title text-center">Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <fg-input
                      type="email"
                      :error="errors[0]"
                      label="Email address"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ errors }"
                  >
                    <fg-input
                      type="password"
                      :error="errors[0]"
                      name="password"
                      label="Password"
                      v-model="password"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <!-- <fg-input>
                    <l-checkbox v-model="subscribe">
                      Subscribe to newsletter
                    </l-checkbox>
                  </fg-input> -->
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                  >
                    Login
                  </button>
                  <br />
                  <div class="forgot">
                    <router-link to="/register" class="card-category"
                      >Forgot your password?</router-link
                    >
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>

<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "../Pages/AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { authenticationService } from "../../../_services/authentication.service";
import { authenticateUser } from "../../../_helpers/authenticateUser";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    // LCheckbox,
    FadeRenderTransition,
    AuthLayout,
  },
  data() {
    return {
      email: "",
      password: "",
      subscribe: true,
      submitted: false,
      loading: false,
      returnUrl: "/login",
      error: "",
    };
  },
  validations: {
    email: { required },
    password: { required },
  },
  methods: {
    async onSubmit() {
      this.submitted = true;

      // // stop here if form is invalid
      // this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }

      this.loading = true;
      // Log the data to be sent
      console.log("Attempting to log in with:", this.email, this.password);

      // Ensure the data is not empty
      const requestData = {
        email: this.email,
        password: this.password,
      };

      // Log the request data
      console.log("Request data:", requestData);
      // Here you could call configureFakeBackend if needed
      authenticateUser(this.email, this.password);
      console.log(this.email);
      authenticationService.login(this.email, this.password).then(
        (user) => {
          // Login was successful, redirect to the return URL
          this.$router.push('/admin/dashboard');
        },
        (error) => {
          // Handle error case
          this.error = error;
          this.loading = false;
        }
      );
    },
    // async onSubmit() {
    //   try {
    //     // Log the request data
    //   console.log("Request data:", requestData);
    //   // Here you could call configureFakeBackend if needed
    //   authenticateUser(this.email, this.password);
    //     const user = await authenticationService.login(this.email, this.password);
    //     this.error = ''; // Clear previous errors
    //     if (this.$route.path !== '/admin/overview') {
    //       this.$router.push('/admin/overview');
    //     }
    //   } catch (error) {
    //     this.error = error.toString();
    //   }
    // },
  
    // async onSubmit() {
    //   this.submitted = true;
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   }

    //   this.loading = true;
    //   try {
    //     const user = await authenticationService.login(
    //       this.email,
    //       this.password
    //     );
    //     // Assuming '/admin/dashboard' is the route you want to redirect to after login
    //     if (this.$route.path !== "/admin/") {
    //       this.$router.push({ path: "/admin/" });
    //     }
    //   } catch (error) {
    //     this.error = error.toString();
    //     this.loading = false;
    //   }
    // },

    // async onOnSubmit() {
    //   try {
    //     const user = await authenticationService.login(this.email, this.password);
    //     this.$router.push(this.returnUrl || "/admin/"); // Use `$router` instance provided by Vue Router
    //   } catch (error) {
    //     this.error = error.toString();
    //   }
    // },

    // async onSubmit() {
    //   this.submitted = true;

    //   // stop here if form is invalid
    //   this.$v.$touch();
    //   if (this.$v.$invalid) {
    //     return;
    //   }

    //   this.loading = true;
    //   // Log the data to be sent
    //   console.log("Attempting to log in with:", this.email, this.password);

    //   // Ensure the data is not empty
    //   const requestData = {
    //     email: this.email,
    //     password: this.password,
    //   };

    //   // Log the request data
    //   console.log("Request data:", requestData);
    //   // Here you could call configureFakeBackend if needed
    //   authenticateUser(this.email, this.password);

    //   authenticationService.login(this.email, this.password).then(
    //     (user) => {
    //       // Login was successful, redirect to the return URL
    //       this.$router.push(this.returnUrl);
    //     },
    //     (error) => {
    //       // Handle error case
    //       this.error = error;
    //       this.loading = false;
    //     }
    //   );
    // },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  created() {
    console.log(authenticationService.currentUserValue);
    console.log(authenticationService);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.$router.push('/admin/dashboard');
    }
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
